<template>
     <div class="flex_layout h100">
        <div class="flex_top">
            <div class="query flex">
                <a-space>
                    <div class="query_box">
                        <a-button class="primary btn" @click="add">Add</a-button>
                    </div>
                </a-space>
            </div>
        </div>
        <div class="flex_body">
            <a-spin :spinning="spinning">
                <a-empty v-if="contact.length == 0" />
                <a-row :gutter="20" v-else>
                    <a-col :span="8" v-for="(item, index) in contact" :key="index">
                        <div class="box_cell">
                            <div class="title flex">
                                <span>Contact {{index+1}}</span>
                                <a-button type="primary" danger size="small" shape="circle" @click="remove(index)">
                                    <template #icon>
                                        <component :is="$antIcons['CloseOutlined']" />
                                    </template>
                                </a-button>
                            </div>
                            <div class="body">
                                <a-form :model="item" :label-col="labelCol" :wrapper-col="wrapperCol">
                                    <a-form-item label="Heading">
                                        <a-input v-model:value="item.heading" placeholder="Heading" />
                                    </a-form-item>
                                    <a-form-item label="Sub heading">
                                        <a-textarea v-model:value="item.sub_heading" :rows="5" placeholder="Sub heading" />
                                    </a-form-item>
                                    <a-form-item label="Tel">
                                        <a-input v-model:value="item.tel"  placeholder="Tel" />
                                    </a-form-item>
                                    <a-form-item label="Phone number">
                                        <a-input v-model:value="item.phone" placeholder="Phone number" />
                                    </a-form-item>
                                    <a-form-item label="Email">
                                        <a-input v-model:value="item.email" placeholder="Email" />
                                    </a-form-item>
                                    <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                                        <a-button type="primary" @click="updateContact(item)">Submit</a-button>
                                    </a-form-item>
                                </a-form>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </a-spin>
        </div>
    </div>
</template>
<script>
import { message } from 'ant-design-vue'
import { getContact, updateContact, removeContact } from '@/apis/contact'
export default {
    name: 'Website setting',
    data(){
        return {
            contact: [],
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
            spinning: false
        }
    },
    methods: {
        add(){
            this.contact.push({
                id: 0,
                heading: '',
                sub_heading: '',
                phone: '',
                email: '',
                tel: ''
            })
        },
        async remove(index){
            const id = this.contact[index].id
            if(id == 0){
                this.contact.splice(index ,1)
            }else{
                this.spinning = true
                const res = await removeContact({id})
                this.spinning = false
                if(res){
                    message.success('success')
                    this.getContactHandler()
                }
            }
        },
        async updateContact(item){
            this.spinning = true
            const res = await updateContact(item)
            this.spinning = false
            if(res){
                message.success('success')
                this.getcontactHandler()
            }
        },
        /**
         * 获取联系方式
         */
        async getContactHandler(){
            this.spinning = true
            const res = await getContact()
            this.spinning = false
            if(res){
                this.contact = res.list
            }
        }
    },
    created(){
        this.getContactHandler()
    }
}
</script>
<style lang="stylus" scoped>
.icon
    width 80px
    display block
    margin-bottom 10px
</style>