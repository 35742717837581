import request from '@/request'

export const getContact = (data = {}) => {
    return request({
      url: '/admin/system/get_contact',
      method: 'get',
      params: data
    })
}
export const updateContact = (data = {}) => {
  return request({
    url: '/admin/system/update_contact',
    method: 'post',
    data
  })
}
export const removeContact = (data = {}) => {
  return request({
    url: '/admin/system/remove_contact',
    method: 'post',
    data
  })
}
